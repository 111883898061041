const routes = [
    {
        path: "/dashboard/admin",
        name: "dashboard-admin",
        component: () => import("@/views/admin/dashboard/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
                {
                    text: "Dashboard",
                    active: true,
                },
            ],
        },
    },
    /******************************************************* */
    // rutas para el módulo de entidades
    /******************************************************* */
    {
        path: "/entidades",
        name: "entidades",
        component: () => import("@/views/admin/entidades/Entidades.vue"),
        meta: {
            pageTitle: "Entidades",
            breadcrumb: [
                {
                    text: "Entidades",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/entidades/:id/ver",
        name: "ver-entidad",
        component: () => import("@/views/admin/entidades/VerEntidad.vue"),
        meta: {
            pageTitle: "Ver entidad",
            breadcrumb: [
                {
                    text: "Entidades",
                    active: false,
                    to: "/entidades",
                },
                {
                    text: "Ver entidad",
                    active: true,
                },
            ],
        },
    },

    /******************************************************** */
    // rutas para el módulo de profesionales
    /******************************************************** */
    {
        path: "/profesionales",
        name: "profesionales",
        component: () =>
            import("@/views/admin/profesionales/Profesionales.vue"),
        meta: {
            pageTitle: "Profesionales",
            breadcrumb: [
                {
                    text: "Profesionales",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/profesionales/:id/ver",
        name: "ver-profesional",
        component: () =>
            import("@/views/admin/profesionales/VerProfesional.vue"),
        meta: {
            pageTitle: "Ver profesional",
            breadcrumb: [
                {
                    text: "Profesionales",
                    active: false,
                    to: "/profesionales",
                },
                {
                    text: "Ver profesional",
                    active: true,
                },
            ],
        },
    },
    /*********************************************************** */
    // rutas para el  módulo de pacientes -> usuarios
    /*********************************************************** */
    {
        path: "/pacientes",
        name: "pacientes",
        component: () => import("@/views/admin/pacientes/Pacientes.vue"),
        meta: {
            pageTitle: "Pacientes",
            breadcrumb: [
                {
                    text: "Pacientes",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/pacientes/:id/ver",
        name: "ver-paciente",
        component: () => import("@/views/admin/pacientes/VerPaciente.vue"),
        meta: {
            pageTitle: "Ver paciente",
            breadcrumb: [
                {
                    text: "Pacientes",
                    active: false,
                    to: "/pacientes",
                },
                {
                    text: "Ver paciente",
                    active: true,
                },
            ],
        },
    },
    /*********************************************************** */
    // rutas para el  módulo de soportes
    /*********************************************************** */
    {
        path: "/soportes",
        name: "soportes",
        component: () => import("@/views/admin/soportes/Soportes.vue"),
        meta: {
            pageTitle: "Soportes",
            breadcrumb: [
                {
                    text: "Soportes",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/soportes/:id_soporte/ver",
        name: "ver-soporte",
        component: () => import("@/views/admin/soportes/VerSoporte.vue"),
        meta: {
            pageTitle: "Ver soporte",
            breadcrumb: [
                {
                    text: "Soportes",
                    active: false,
                    to: "/soportes",
                },
                {
                    text: "Ver soporte",
                    active: true,
                },
            ],
        },
    },
];
export default routes;
