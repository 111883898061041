<template>
    <b-modal
        id="modal"
        ref="modal"
        :size="size"
        centered
        :busy="busy"
        @ok="handleOk"
        scrollable
        :lazy="true"
        :row-dblclicked="selectedRow"
    >
        <template v-if="title.length" v-slot:modal-title>{{ title }}</template>
        <template v-slot:modal-ok>{{ aceptar }}</template>
        <template v-slot:modal-cancel>{{ cancelar }}</template>

        <template v-if="cerrar" v-slot:modal-footer>
            <b-button variant="light" @click="$bvModal.hide('modal')"
                >Cerrar</b-button
            >
        </template>

        <p v-if="text.length" class="mt-1 text-center">{{ text }}</p>

        <slot></slot>
    </b-modal>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "sm",
        },
        busy: {
            type: Boolean,
            default: false,
        },
        aceptar: {
            type: String,
            default: "Aceptar",
        },
        aceptarDisabled: {
            type: Boolean,
            default: false,
        },
        cancelar: {
            type: String,
            default: "Cancelar",
        },
        cancelarDisabled: {
            type: Boolean,
            default: false,
        },
        cerrar: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        function toggle() {
            context.refs.modal.toggle();
        }

        function handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            context.emit("aceptar");
        }

        function selectedRow(value) {
            console.log(value);
        }

        return { handleOk, selectedRow, toggle };
    },
};
</script>
