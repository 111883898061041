import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
function CheckIcon(estado) {
    switch (estado) {
        case "success":
            return "CheckIcon";

        case "warning":
            return "AlertTriangleIcon";
        case "info":
            return "InfoIcon";
        default:
            break;
    }
}

export default {
    methods: {
        notificacion(message, icono = "XIcon", estado = "danger") {
            return this.$toast({
                component: ToastificationContent,
                props: {
                    title: message ?? "Error",
                    icon: icono ?? CheckIcon(estado),
                    variant: estado,
                },
            });
        },
        defaultDelay(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },
        catch(error) {
            if (error.status == 422) {
                this.notificacion(error.data.message ?? error, null, "warning");
            } else {
                this.notificacion(error.data.message ?? error);
            }
        },
        formatDate(date, format = "DD MMM YYYY") {
            if (!date) return null;
            return dayjs(date).format(format);
        },
        getGenero(genero) {
            switch (genero) {
                case 0:
                    return "Indefinido";
                case 1:
                    return "Hombre";
                case 2:
                    return "Mujer";
                default:
                    break;
            }
        },
        getEdad(fecha) {
            const edad = dayjs(fecha);
            const now = dayjs();
            return now.diff(edad, "year");
        },
        getRol(idRol) {
            switch (idRol) {
                case 1:
                    return "Super admin";
                case 2:
                    return "Admin";
                case 3:
                    return "Paciente";
                case 4:
                    return "Profesional";
                default:
                    return "Indefinido";
            }
        },
    },
};
