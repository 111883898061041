import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
// import VAnimateCss from 'v-animate-css';
import App from "./App.vue";
import store from "./store";
import router from "./router";

import mixins from "@/mixins";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

const dayjs = require("dayjs");
require("dayjs/locale/es");
dayjs.locale("es");
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
// Vue.use(VAnimateCss)

//mixins
Vue.mixin(mixins);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("@core/scss/vue/libs/vue-select.scss");

require("animate.css");

Vue.config.productionTip = false;

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount("#app");
