<template>
    <b-sidebar
        id="sidebar"
        ref="sidebar"
        shadow
        bg-variant="white"
        backdrop
        right
        width="25rem"
        header-class="border-bottom"
        lazy
        :visible="localShow"
    >
        <template v-slot:header="{ hide }">
            <b-row v-if="customHeader">
                <b-col>
                    <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="toggle"
                    >
                    </feather-icon>
                </b-col>
                <b-col cols="auto">
                    {{ title }}
                </b-col>
            </b-row>
            <slot v-else name="header"></slot>
        </template>

        <template v-slot:default>
            <slot></slot>
        </template>

        <template v-slot:footer="{ hide }">
            <b-row v-if="customFooter" class="mx-0 py-1 border-top">
                <b-col>
                    <b-button
                        variant="primary"
                        class="mr-2"
                        :disabled="cargando"
                        @click="handleOk"
                    >
                        <b-spinner v-if="cargando" small></b-spinner>
                        {{ aceptar }}</b-button
                    >
                    <b-button variant="light" @click="toggle">{{
                        cancelar
                    }}</b-button>
                </b-col>
            </b-row>
            <slot v-else name="footer"></slot>
        </template>
    </b-sidebar>
</template>
<script>
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
export default {
    props: {
        id: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        cargando: {
            type: Boolean,
            default: false,
        },
        customHeader: {
            type: Boolean,
            default: true,
        },
        customFooter: {
            type: Boolean,
            default: true,
        },
        aceptar: {
            type: String,
            default: "Aceptar",
        },
        cancelar: {
            type: String,
            default: "Cerrar",
        },
    },
    setup(props, context) {
        const localShow = ref(false);

        function toggle() {
            localShow.value = !localShow.value;
        }

        function handleOk() {
            context.emit("aceptar");
        }

        return { handleOk, toggle, localShow };
    },
};
</script>
<style lang="css" scope>
.sidebar-header {
    background-color: #f6f6f6;
}
</style>
