const routes = [
    /****************************************************************************/
    // rutas dashboard
    /************************************************************************** */

    {
        path: "/dashboard/profesional",
        name: "dashboard-profesional",
        component: () => import("@/views/profesional/dashboard/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
                {
                    text: "Dashboard",
                    active: true,
                },
            ],
        },
    },
    /****************************************************************************/
    // rutas pacientes
    /************************************************************************** */
    {
        path: "/mis-pacientes",
        name: "mis-pacientes",
        component: () =>
            import("@/views/profesional/pacientes/Pacientes.vue").then(
                (m) => m.default || m
            ),
        meta: {
            pageTitle: "Mis Pacientes",
            breadcrumb: [
                {
                    text: "Mis Pacientes",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/mis-pacientes/:id_paciente/ver",
        name: "ver-mis-paciente",
        component: () =>
            import("@/views/profesional/pacientes/VerPacientes").then(
                (m) => m.default || m
            ),
        meta: {
            pageTitle: "Ver paciente",
            breadcrumb: [
                {
                    text: "Mis Pacientes",
                    active: false,
                    to: "/mis-pacientes",
                },
                {
                    text: "Ver paciente",
                    active: true,
                },
            ],
        },
    },
    /****************************************************************************/
    // rutas asistencias
    /************************************************************************** */
    {
        path: "/asistencias",
        name: "asistencias",
        component: () =>
            import("@/views/profesional/asistencias/Asistencias.vue"),
        meta: {
            pageTitle: "Asistencias",
            breadcrumb: [
                {
                    text: "Asistencias",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/asistencias/:id/ver",
        name: "ver-asistencia",
        component: () =>
            import("@/views/profesional/asistencias/VerAsistencia.vue"),
        meta: {
            pageTitle: "Ver asistencia",
            breadcrumb: [
                {
                    text: "Asistencias",
                    active: false,
                    to: "/asistencias",
                },
                {
                    text: "Ver asistencia",
                    active: true,
                },
            ],
        },
    },
    /************************************************************************** */
    // rutas agenda
    /************************************************************************** */
    {
        path: "/agenda",
        name: "agenda",
        component: () => import("@/views/profesional/agenda/Agenda.vue"),
        meta: {
            pageTitle: "Mi agenda",
            breadcrumb: [
                {
                    text: "Mi agenda",
                    active: true,
                },
            ],
        },
    },
];

export default routes;
