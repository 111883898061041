import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

import RutasProfesional from "@/router/profesional";
import RutasAdmin from "@/router/admin";
import Auth from "@/services/auth";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        ...RutasProfesional,
        ...RutasAdmin,
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("@/views/UserProfile.vue"),
        },
        {
            path: "/loading-home",
            name: "loading-page",
            component: () => import("@/views/Loading.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/error/Error404.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "*",
            redirect: "login",
        },
    ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});
router.beforeEach(async (to, from, next) => {
    if (to.name == "login") {
        await store.dispatch("auth/logOut");
        return next();
    }

    if (localStorage.getItem("token") && store.state.auth.token) {
        return next();
    }

    try {
        store.dispatch("auth/mountToken");
        const { data } = await Auth.getUser();
        store.dispatch("auth/checkToken", data.user);
        next();
    } catch (error) {
        store.dispatch("auth/logOut");
        console.log(error);
        next("/home");
    }
});

export default router;
