import Auth from "@/services/auth";
import axiosIns from "@/libs/axios";
export default {
    namespaced: true,
    state: {
        token: null,
        user: {},
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        user: (state) => {
            return state.user;
        },
    },
    mutations: {
        setUserData(state, payload) {
            state.user = payload;
        },
        setToken(state, payload) {
            state.token = payload;
            localStorage.setItem("token", payload);
        },
        revokeToken(state) {
            state.token = null;
            state.user = {};
            localStorage.removeItem("token");
        },
        mountToken(state) {
            state.token = localStorage.getItem("token");
        },
    },
    actions: {
        // action valida solo para el login
        assignToken({ commit }, payload) {
            axiosIns.defaults.headers.Authorization = "Bearer " + payload.token;
            console.log(axiosIns.defaults.headers.Authorization);
            commit("setToken", payload.token);
            commit("setUserData", payload.user);
        },
        logOut({ commit }) {
            axiosIns.defaults.headers.Authorization = null;
            commit("revokeToken");
        },
        mountToken({ commit }) {
            commit("mountToken");
        },
        // action valida solo para el router
        checkToken({ commit }, payload) {
            commit("setUserData", payload);
        },
    },
};
