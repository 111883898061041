// axios
import axios from "axios";
const api = process.env.VUE_APP_BASE_URL;

const axiosIns = axios.create({
    baseURL: api,
    timeout: 1000,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    // withCredentials: true,
});

axiosIns.interceptors.response.use(
    async (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.location.replace("/login");

            return Promise.reject(error.response);
        } else if (error.response.status >= 400) {
            console.log(error.response);
            return Promise.reject(error.response);
        }
    }
);

export default axiosIns;
