<template>
    <div>
        <b-row class="m-0 p-0">
            <b-col
                v-if="isPaginated"
                xs="12"
                :md="buttonActivated ? 5 : 5"
                :lg="buttonActivated ? 5 : 8"
                :xl="buttonActivated ? 6 : 9"
                cols="12"
                class="d-flex align-items-center justify-content-start py-1 mb-md-0"
            >
                <label>Mostrar</label>
                <v-select
                    v-model="perPage"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
            </b-col>
            <b-col
                v-if="searchActivated"
                :xs="buttonActivated ? 4 : 12"
                :sm="buttonActivated ? 9 : 12"
                :md="buttonActivated ? 5 : 7"
                :xl="buttonActivated ? 4 : 3"
                :lg="buttonActivated ? 5 : 4"
                class="py-1"
            >
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block"
                    :placeholder="searchPlaceholder"
                />
            </b-col>
            <b-col
                v-if="buttonActivated"
                xs="12"
                sm="auto"
                md="auto"
                lg="auto"
                xl="auto"
                class="py-1"
            >
                <slot name="boton"> </slot>
            </b-col>
        </b-row>
        <b-table
            id="table-transition"
            ref="TablaGeneral"
            class="position-relative"
            hover
            :items="itemsTable"
            responsive
            :fields="columns"
            primary-key="id"
            :busy.sync="loading"
            :per-page="perPage"
            :current-page="currentPage"
            :tbody-transition-props="transProps"
        >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
            </template>
            <!-- en vue 3 remplazar $scopedSlots por $slots -->
            <template
                v-for="(_, slotName) of $scopedSlots"
                v-slot:[slotName]="scope"
            >
                <slot :name="slotName" v-bind="scope" />
            </template>
            <template v-slot:empty="scope">
                <p v-if="showEmpty" class="m-1 text-center">
                    {{ scope.emptyText }}
                </p>
            </template>
        </b-table>
        <div v-if="isPaginated" class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted"
                        >Mostrando {{ itemsTable.length }} registros</span
                    >
                </b-col>
                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="itemsTable.length"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import {
    ref,
    onMounted,
    computed,
    watch,
    reactive,
} from "@vue/composition-api";
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
        searchPlaceholder: {
            type: String,
            default: "Buscar",
        },
        searchActivated: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isPaginated: {
            type: Boolean,
            default: true,
        },
        buttonActivated: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const currentPage = ref(1);
        const perPage = ref(10);

        const searchQuery = ref("");

        const perPageOptions = ref([10, 25, 50, 100]);

        const showEmpty = ref(false);

        const transProps = reactive({
            name: "flip-list",
        });

        const itemsTable = computed(() =>
            props.items.filter(
                (data) =>
                    !searchQuery.value ||
                    data.nombre
                        .toLowerCase()
                        .includes(searchQuery.value.toLowerCase())
            )
        );

        setTimeout(() => {
            if (props.items) showEmpty.value = true;
        }, 500);

        return {
            perPage,
            perPageOptions,
            searchQuery,
            currentPage,
            transProps,
            itemsTable,
            showEmpty,
        };
    },
};
</script>
<style lang="css">
table#table-transition .flip-list-move {
    transition: transform 1s;
}
</style>
