import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Table from "@/components/table.vue";
import Modal from "@/components/modal.vue";
import Sidebar from "@/components/sidebar.vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("tablaGeneral", Table);
Vue.component("modalGeneral", Modal);
Vue.component("sidebarGeneral", Sidebar);
Vue.component("vSelect", vSelect);
Vue.component("vueApexCharts", VueApexCharts);
Vue.component("flatPickr", flatPickr);
Vue.component("AppTimeline", AppTimeline);
Vue.component("AppTimelineItem", AppTimelineItem);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule], // assign message
    });
});
